import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./nav.css";
import logo from "../logos.png";

export default function TopNav() {
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarExpanded(!navbarExpanded);
  };

  return (
    <div className="container-fluid nav-back">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg d-flex nav-back fixed-top navbar-responsive ">
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <h2
              className="navbar-toggler mt-2"
              onClick={handleNavbarToggle}
              style={{ border: "1px solid black" }}
            >
              {navbarExpanded ? (
                <FontAwesomeIcon className="text-dark tog" icon={faXmark} />
              ) : (
                <FontAwesomeIcon className="text-dark tog" icon={faBars} />
              )}
            </h2>
            <div className="ps-2">
              <Link to="/" className="navbar-brand">
                <img
                  src={logo}
                  className="logos"
                  alt="logo"
                  style={{ height: "2.7rem", width: "9.5rem" }}
                />
              </Link>
            </div>
            <div
              className={`collapse navbar-collapse justify-content-end ${
                navbarExpanded ? "show" : ""
              }`}
              id="navbarNav"
            >
              <ul className="navbar-nav nave-text">
                <li className="nav-item ps-lg-4">
                  <Link
                    className="nav-link nave-text text-start"
                    to="/Reportuplode"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item ps-lg-4">
                  <Link
                    className="nav-link text-start nave-text"
                    to="/Totalreport"
                  >
                    View Reports
                  </Link>
                </li>
                <li className="nav-item ps-lg-4">
                  <Link
                    className="nav-link text-start nave-text"
                    to="/"
                  >
                  <button className="logoutbtn"> Log out</button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
