// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.nav-back {
    background-color: #FAFAFA;

  }
  
  .logo1{
     height: "2.8rem";
      width: "10rem"
  }

  
  .navbar-responsive {
    padding: 0.5rem 1rem;
  }
  
  .navbar-toggler {
    cursor: pointer;
    color: hsla(0, 0%, 100%, 0.833) !important;
    border-color: rgba(2, 2, 2, 0.1) !important;
  }
  
  .tog {
    font-size: 1.5rem;
  }
  
  .nav-link {
    color: #575757;
  }
  
  @media (max-width: 992px) {
    .navbar-brand {
      order: 2;
      margin-left: auto;
    }
  
    .navbar-toggler {
      order: 1;
    }
  
    .navbar-collapse {
      order: 3;
      width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .navbar-collapse {
      text-align: center;
    }
  
    .nav-item {
      padding: 0.5rem 0;
    }
    .logos{
        height: 2.0rem;
        width: 8rem;
      }
  }
  .logoutbtn{
    padding: 0px 12px 3px 12px;
    border:none;
    background-color: rgb(246, 210, 68);
    border-radius: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/component/nav/nav.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;;EAE3B;;EAEA;KACG,gBAAgB;MACf;EACJ;;;EAGA;IACE,oBAAoB;EACtB;;EAEA;IACE,eAAe;IACf,0CAA0C;IAC1C,2CAA2C;EAC7C;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE;MACE,QAAQ;MACR,iBAAiB;IACnB;;IAEA;MACE,QAAQ;IACV;;IAEA;MACE,QAAQ;MACR,WAAW;IACb;EACF;;EAEA;IACE;MACE,kBAAkB;IACpB;;IAEA;MACE,iBAAiB;IACnB;IACA;QACI,cAAc;QACd,WAAW;MACb;EACJ;EACA;IACE,0BAA0B;IAC1B,WAAW;IACX,mCAAmC;IACnC,mBAAmB;EACrB","sourcesContent":["\n\n.nav-back {\n    background-color: #FAFAFA;\n\n  }\n  \n  .logo1{\n     height: \"2.8rem\";\n      width: \"10rem\"\n  }\n\n  \n  .navbar-responsive {\n    padding: 0.5rem 1rem;\n  }\n  \n  .navbar-toggler {\n    cursor: pointer;\n    color: hsla(0, 0%, 100%, 0.833) !important;\n    border-color: rgba(2, 2, 2, 0.1) !important;\n  }\n  \n  .tog {\n    font-size: 1.5rem;\n  }\n  \n  .nav-link {\n    color: #575757;\n  }\n  \n  @media (max-width: 992px) {\n    .navbar-brand {\n      order: 2;\n      margin-left: auto;\n    }\n  \n    .navbar-toggler {\n      order: 1;\n    }\n  \n    .navbar-collapse {\n      order: 3;\n      width: 100%;\n    }\n  }\n  \n  @media (max-width: 576px) {\n    .navbar-collapse {\n      text-align: center;\n    }\n  \n    .nav-item {\n      padding: 0.5rem 0;\n    }\n    .logos{\n        height: 2.0rem;\n        width: 8rem;\n      }\n  }\n  .logoutbtn{\n    padding: 0px 12px 3px 12px;\n    border:none;\n    background-color: rgb(246, 210, 68);\n    border-radius: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
