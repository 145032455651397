import React, { useEffect, useState } from "react";
import './view.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function View() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://py.rccnorth.in/report')
      .then(response => {
        setEvents(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setLoading(false);
      });
  }, []);

  const handleEdit = (event) => {
    navigate(`/edit/${event.Event_id}`, { state: { event } });
  };

  const handleDelete = (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      axios.delete(`https://py.rccnorth.in/report/${eventId}`)
        .then(response => {
          if (response.status === 200) {
            setEvents(events.filter(event => event.Event_id !== eventId));
            alert('Event deleted successfully');
          }
        })
        .catch(error => {
          console.error('There was an error deleting the event!', error);
          alert('Failed to delete the event');
        });
    }
  };

  return (
    <div className="view">
      <div className="container">
        {loading ? (
          <div className="loading text-center">Loading..</div>
        ) : (
          <div className="row">
            {events.map((event, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12 mt-3">
                <div className="card">
                  <img src={event.photo} className="card-img-top" alt="Event" />
                  <div className="card-body">
                    <h5 className="card-title">{event.Heading}</h5>
                    <p className="mt-2 mb-0"><strong>id:</strong> {event.Event_id}</p>
                    <p className="mt-2 mb-0"><strong>Date:</strong> {event.Date}</p>
                    <div className="row mt-2">
                      <p className="m-0 col-7"><strong>Hours:</strong> {event.Hours}</p>
                      <button className="editbtn col-3" onClick={() => handleEdit(event)}>Edit</button>
                      <div className="col-3">
                        <button className="deletebtn" onClick={() => handleDelete(event.Event_id)}>Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default View;
