import React, { useState } from "react";
import uplode from "../component/uplodeimg.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./report.css";

export const Report = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [eventDetails, setEventDetails] = useState({
    Date: "",
    Service: "",
    Heading: "",
    content: "",
    Hours: "",
    Cost: "",
    Volunteer: "",
    beneficiaries: "",
    value: "",
    Rupees: "",
    Members: "",
    Guest: "",
    Rotaractors: "",
    Family: "",
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));
      setImageFile(file);
    } else {
      toast.error("Please select an image file.");
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("Photo", imageFile);
    }

    Object.keys(eventDetails).forEach((key) => {
      formData.append(key, eventDetails[key]);
    });

    try {
      const response = await fetch("https://py.rccnorth.in/report", {
       
        method: "POST",
        body: formData,
      });
      console.log(response)

      if (response.ok) {
        toast.success("Profile saved successfully!");
        setProfileImage(null);
        setImageFile(null);
        setEventDetails({
          Date: "",
          Service: "",
          Heading: "",
          content: "",
          Hours: "",
          Cost: "",
          Volunteer: "",
          beneficiaries: "",
          value: "",
          Rupees: "",
          Members: "",
          Guest: "",
          Rotaractors: "",
          Family: "",
        });
      } else {
        toast.error("Failed to save the profile.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while saving the profile.");
    }
  };

  const handleEventDetailChange = (e) => {
    const { name, value } = e.target;
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <>
     
      <div className="manager">
        <ToastContainer />
        <div className="container">
          <h2 className="manager-texth">Event Report Add</h2>
          <div className="manager-content">
            <form className="sheulde-orderform mt-4">
              <p className="text-center">Event Action Photography</p>
              <div className="d-flex justify-content-center">
                <label htmlFor="imageUpload" className="imageLabel">
                  {profileImage ? (
                    <img src={profileImage} alt="Profile" className="image" />
                  ) : (
                    <div className="placeholderteam">
                      <span>
                        <img className="uploadIcon" src={uplode} alt="Upload Icon" />
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  className="imageUploadInput"
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </div>
              <div className="row mt-2">
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="heading">Event Name</label>
                  <input
                    type="text"
                    id="heading"
                    placeholder="Heading"
                    name="Heading"
                    value={eventDetails.Heading}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="date">Date</label>
                  <input
                    type="date"
                    id="date"
                    name="Date"
                    value={eventDetails.Date}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="service">Avenue of Projects</label>
                  <input
                    type="text"
                    id="service"
                    placeholder="Service"
                    name="Service"
                    value={eventDetails.Service}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="hours">Duration In Hours</label>
                  <input
                    type="number"
                    id="hours"
                    placeholder="Hours"
                    name="Hours"
                    value={eventDetails.Hours}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-12 mt-3">
                  <label className="form-label" htmlFor="content">Event Description</label>
                  <textarea
                    id="content"
                    placeholder="Content"
                    name="content"
                    value={eventDetails.content}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="cost">Cost Of Club in Rupees</label>
                  <input
                    type="number"
                    id="cost"
                    placeholder="Cost"
                    name="Cost"
                    value={eventDetails.Cost}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="volunteer">Volunteer Hours</label>
                  <input
                    type="text"
                    id="volunteer"
                    placeholder="Volunteer"
                    name="Volunteer"
                    value={eventDetails.Volunteer}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="beneficiaries">No. of Beneficiaries</label>
                  <input
                    type="text"
                    id="beneficiaries"
                    placeholder="Beneficiaries"
                    name="beneficiaries"
                    value={eventDetails.beneficiaries}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="value">Value to Beneficiaries</label>
                  <input
                    type="number"
                    id="value"
                    placeholder="Value"
                    name="value"
                    value={eventDetails.value}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="members">No of Members Attended</label>
                  <input
                    type="text"
                    id="members"
                    placeholder="Members"
                    name="Members"
                    value={eventDetails.Members}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="guest">No of Guest Members Attended</label>
                  <input
                    type="text"
                    id="guest"
                    placeholder="Guest"
                    name="Guest"
                    value={eventDetails.Guest}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="rotaractors">No of Rotaractors Attended</label>
                  <input
                    type="text"
                    id="rotaractors"
                    placeholder="Rotaractors"
                    name="Rotaractors"
                    value={eventDetails.Rotaractors}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
                <div className="col-6 mt-3">
                  <label className="form-label" htmlFor="family">No of Family Attended</label>
                  <input
                    type="text"
                    id="family"
                    placeholder="Family"
                    name="Family"
                    value={eventDetails.Family}
                    onChange={handleEventDetailChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <button
                  type="button"
                  onClick={handleSave}
                  className="Team-managementbtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
