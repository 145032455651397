import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Report } from './component/report';
import Login from './component/login/login';
import View from './component/view/view';
import Nav from './component/nav/nav';
import EditReport from './component/view/editreport';
import { AuthProvider } from './component/privaterout/Authandicate';
import PrivateRoute from './component/privaterout/private';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route 
            path="/Reportupload" 
            element={
              <PrivateRoute>
                <Nav />
                <Report />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/Totalreport" 
            element={
              <PrivateRoute>
                <Nav />
                <View />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/edit/:id" 
            element={
              <PrivateRoute>
                <Nav />
                <EditReport />
              </PrivateRoute>
            } 
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
