// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view{
    margin-top: 55px;
    margin-bottom: 10px;
  }
 
  
  .loading {
    font-size: 1.5rem;
    color: #333;
  }
  .editbtn{
    padding: 2px 10px ;
    background-color: rgb(255, 238, 172);
    border-radius: 10px;
    width:100%;
    max-width: 60px;
    border: none;

  }
  .card-img-top {
    width: 100%; 
    height: 200px; 
    object-fit: cover; 
  }
  .deletebtn{
    padding: 2px 10px ;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    width:100%;
    color: #ffff;
    max-width: 80px;
    border: none;
  }`, "",{"version":3,"sources":["webpack://./src/component/view/view.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;EACrB;;;EAGA;IACE,iBAAiB;IACjB,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,oCAAoC;IACpC,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,YAAY;;EAEd;EACA;IACE,WAAW;IACX,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,kBAAkB;IAClB,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,eAAe;IACf,YAAY;EACd","sourcesContent":[".view{\n    margin-top: 55px;\n    margin-bottom: 10px;\n  }\n \n  \n  .loading {\n    font-size: 1.5rem;\n    color: #333;\n  }\n  .editbtn{\n    padding: 2px 10px ;\n    background-color: rgb(255, 238, 172);\n    border-radius: 10px;\n    width:100%;\n    max-width: 60px;\n    border: none;\n\n  }\n  .card-img-top {\n    width: 100%; \n    height: 200px; \n    object-fit: cover; \n  }\n  .deletebtn{\n    padding: 2px 10px ;\n    background-color: rgb(0, 0, 0);\n    border-radius: 10px;\n    width:100%;\n    color: #ffff;\n    max-width: 80px;\n    border: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
